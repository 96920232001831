/**
 * Do not remove any keys!!
 * The shape of this file is to remain the same so that all options are know.
 *
 * If a key does not have a value, set the value to undefined.
 */
module.exports = {
  communityName: "The Brickyard",
  gtagId: "G-0MVTEC7SLW",
  logo: {
    src: "https://cdn.maxxpm.com/thebrickyard/logosandbanners/the-brickyard-long.png",
    alt: "The Brickyard Logo",
  },
  nav: {
    bannerSubtitle: undefined,
    bannerTitle: undefined,
    bannerCTA: undefined,
  },
  footer: {
    address: "3710 N Centrepoint Way",
    cityStateZip: "Meridian, ID 83646",
    phone: "(208) 908-7202",
    copyrightYear: "2023",
    logo: "https://cdn.maxxpm.com/thebrickyard/logosandbanners/the-brickyard-long-white.png",
  },
  listMarkerSrc:
    "https://cdn.maxxpm.com/thebrickyard/logosandbanners/Brickyard+bullet+point.png",
  hasCalendlyPopup: false,
  calendlyLink: undefined,
  home: {
    banner: {
      image:
        "https://cdn.maxxpm.com/thebrickyard/exteriors/Image20221003145353.jpg",
      video: undefined,
      title: "Upgraded Townhomes and Apartments",
      subtitle: "We Can't Wait To Welcome You Home!",
    },
    aboutUs: {
      paragraphs: [
        "The Brickyard is a premier apartment and townhouse community located just off Eagle Road, minutes away from I-84. This gorgeous location provides outstanding views of Bogus Basin and The Village at Meridian just down the road!",
        "Residents of The Brickyard enjoy access to exclusive amenities, including a well-equipped fitness center, temperature-controlled pool, and a gorgeous clubhouse. With an exciting playground for children and a dog park for your pets, The Brickyard is a family-friendly community. Our 6 models of townhomes and apartments include open floorplans, tons of natural light, attached garages and more. We live for the moment we get to welcome you home!",
      ],
      image: {
        src: "https://cdn.maxxpm.com/thebrickyard/3storypayton/4E0A4226-HDR_4.jpg",
        alt: "The Brickyard Pool",
      },
    },
    floorplanTitle: "Explore Our Floor Plans and Take a 3D Virtual Tour",
    floorplans: [
      {
        hasImage: true,
        image: {
          src: "https://cdn.maxxpm.com/thebrickyard/logosandbanners/payton_floor_plan.jpg",
          alt: "3 Story Townhome Floor Plan",
        },
        hasWalkthrough: true,
        walkthrough: {
          src: "https://my.matterport.com/show/?m=H5AC7vTrP4k",
          title: "Payton model 3d walkthrough",
        },
      },
      {
        hasImage: true,
        image: {
          src: "https://cdn.maxxpm.com/thebrickyard/logosandbanners/tucker_floor_plan.jpg",
          alt: "2 Story Townhome Floor Plan",
        },
        hasWalkthrough: true,
        walkthrough: {
          src: "https://my.matterport.com/show/?m=cVi1NQoL4gQ",
          title: "Tucker model 3d walkthrough",
        },
      },
      {
        name: "Studio Apartment",
        hasImage: true,
        image: {
          src: "https://cdn.maxxpm.com/thebrickyard/logosandbanners/by-studio-scaled-1024x532.jpeg",
          alt: "Studio Floor Plan",
        },
        hasWalkthrough: false,
      },
      {
        name: "1-Bed, 1-Bath Apartment",
        hasImage: true,
        image: {
          src: "https://cdn.maxxpm.com/thebrickyard/logosandbanners/by-1-bed-scaled-1024x684.jpeg",
          alt: "1 bedroom Floor Plan",
        },
        hasWalkthrough: false,
      },
      {
        name: "2-Bed, 1-Bath Apartment",
        hasImage: true,
        image: {
          src: "https://cdn.maxxpm.com/thebrickyard/logosandbanners/by-2-bed-scaled-1024x636.jpeg",
          alt: "2 bedroom Floor Plan",
        },
        hasWalkthrough: false,
      },
      {
        name: "3-Bed, 2-Bath Apartment",
        hasImage: true,
        image: {
          src: "https://cdn.maxxpm.com/thebrickyard/logosandbanners/by-3-bed-scaled-1024x694.jpeg",
          alt: "3 bedroom Floor Plan",
        },
        hasWalkthrough: false,
      },
      {
        hasImage: false,
        hasWalkthrough: true,
        walkthrough: {
          src: "https://my.matterport.com/show/?m=gxBGZh8UGhf",
          title: "Clubhouse 3d walkthrough",
        },
      },
    ],
    midContainerImage:
      "https://cdn.maxxpm.com/thebrickyard/logosandbanners/The+Brickyard+Exterior.jpg",
    imgRow: [
      {
        src: "https://cdn.maxxpm.com/thebrickyard/3storypayton/4E0A4233-HDR_4.jpg",
        alt: "Brickyard townhomes exterior photo",
      },
      {
        src: "https://cdn.maxxpm.com/thebrickyard/3storypayton/4E0A4243-HDR_3.jpg",
        alt: "Brickyard townhomes playground",
      },
      {
        src: "https://cdn.maxxpm.com/thebrickyard/3storypayton/4E0A4267-HDR_3_1.jpg",
        alt: "Brickyard townhomes exterior photo",
      },
    ],
    animalPolicy: {
      img: {
        src: "https://cdn.maxxpm.com/stockphotos/boy+kissing+dog.jpg",
        alt: "Animal policy stock photo",
      },
      policies: [
        "No more than 2 animals are allowed per property",
        "Cats/Dogs: $250/animal*",
        "$50/animal/month for animals under 50lbs*",
        "$75/animal/month for animals 50lbs+*",
        "One-time $75/animal DNA testing fee*",
        "Caged animals: $25/animal/month*",
        "Fish tanks are not permitted",
        "Rabbits and rodents of any kind are not permitted",
      ],
      breedRestrictions: [
        "Pit Bulls & Staffordshire Terriers",
        "Doberman Pinschers",
        "Rottweilers",
        "Chows",
        "Great Danes",
        "Presa Canarios/ Mastiffs",
        "Akitas",
        "Alaskan Malamutes",
        "Huskies",
        "German Shepherds",
        "Shar Peis",
        "Wolf-Hybrids",
      ],
    },
  },
  availability: {
    banner: {
      image:
        "https://cdn.maxxpm.com/thebrickyard/exteriors/Image20221003145353.jpg",
      video: undefined,
      title: "Available Units",
      subtitle:
        "Unit availability is updated often from Monday-Friday during business hours. Evenings and weekends may not reflect accurate availability dates.",
    },
    hasWaitingList: false,
    waitingListJotformId: undefined,
    propertyGroup: "LEADS-BM",
  },
  faq: {
    banner: {
      image:
        "https://cdn.maxxpm.com/lagunafarms/logoandbanners/girls+on+bed+with+dog.jpg",
    },
  },
};
